import dateFormatHelper from '@/helpers/dateFormatHelper'
import { i18n } from '@/i18n/setup'
import Constants from 'Constants'
import { differenceInCalendarYears, parseISO } from 'date-fns'
import { cloneDeep } from 'lodash'

export default {
  steps(state) {
    return state.contract?.steps ?? []
  },

  countriesFiltered(state) {
    return state.countries.filter(
      (country) => country.id !== Constants.country.RUSSIA,
    )
  },

  // TODO: переделать, так как СО только в наших пенатах
  educationTypesAvailable(state, getters, rootState, rootGetters) {
    const schoolPlatformId = rootState.client.profile.schoolPlatform?.id
    const isAvailableAnyCommercialOffer =
      rootGetters['client/profile/isAvailableAnyCommercialOffer']

    const schoolPlatform = state.schools.find(
      (school) => school.id === schoolPlatformId,
    )

    let schoolPlatformEducationTypes =
      schoolPlatform?.educationTypes ?? state.educationTypes

    schoolPlatformEducationTypes = schoolPlatformEducationTypes.filter(
      (type) => type.id !== Constants.educationType.FULL_TIME,
    )

    if (!isAvailableAnyCommercialOffer) {
      schoolPlatformEducationTypes = schoolPlatformEducationTypes.filter(
        (type) => type.id !== Constants.educationType.EXTRAMURAL,
      )
    }

    return schoolPlatformEducationTypes
  },

  allStepsValid(state) {
    return Object.values(state.validStatus).every((status) => status === true)
  },

  isEducationTypeExtramural(state) {
    return (
      state.contract.educationType?.id === Constants.educationType.EXTRAMURAL
    )
  },

  isEducationTypeFamily(state) {
    return state.contract.educationType?.id === Constants.educationType.FAMILY
  },

  // ContractData

  contractSchools(state) {
    const sortedSchools = [
      ...state.schools,
    ].sort((a, b) => a.id - b.id)

    if (
      process.iblsEnv.IBLS_SCHOOLS_FORBIDDEN_TO_SUBMIT_NEW_CONTRACTS?.length
    ) {
      const tempArr =
        process.iblsEnv.IBLS_SCHOOLS_FORBIDDEN_TO_SUBMIT_NEW_CONTRACTS.split(
          ',',
        ).map((numb) => Number(numb))

      return sortedSchools.filter((school) => !tempArr.includes(school.id))
    }

    return sortedSchools
  },

  hasContractSchools(state, getters) {
    return Boolean(getters.contractSchools.length)
  },

  contractFamilyEducationGrades(state) {
    const gradesIdsForExclude = [
      9,
      11,
    ]

    return state.familyEducationGrades.filter(
      (grade) => !gradesIdsForExclude.includes(grade.id),
    )
  },

  contractCities(state, getters) {
    const schools = getters.contractSchools
    const grade = getters.contractGrade
    const result = {}

    if (grade) {
      for (const school of schools) {
        if (school.grades.some((i) => i.id === grade.id)) {
          result[school.city.id] = school.region
        }
      }
    }

    if (state.isSchools3rdParty) {
      return Object.values(result)
    }

    const nextMaxIdNumber =
      Object.values(result).reduce(
        (acc, val) => (val.id > acc ? val.id : acc),
        0,
      ) + 1

    const anotherCity = {
      id: nextMaxIdNumber,
      name: 'Другое',
    }

    return [
      ...Object.values(result),
      anotherCity,
    ]
  },

  contractCity(state) {
    return state.contract.school?.region
  },

  hasSettings(state) {
    return state.contractSettings !== null
  },

  hasAcademicYearPresetSettings(state, getters) {
    if (getters.hasSettings) {
      return state.contractSettings.academicYearPresetSettings.length !== 0
    }

    return false
  },

  hasStreams(state, getters) {
    let hasStreams = false

    if (getters.hasSettings) {
      if (getters.hasAcademicYearPresetSettings) {
        for (const presetSetting of state.contractSettings
          .academicYearPresetSettings) {
          if (presetSetting.gradeSettings) {
            for (const gradeSetting of presetSetting.gradeSettings) {
              if (gradeSetting.streams && gradeSetting.streams.length > 0) {
                hasStreams = true
              }
            }
          }
        }
      }
    }

    return hasStreams
  },

  isRequestFormTypeProfile(state) {
    return (
      state.contractSettings?.requestFormType?.id ===
      Constants.requestFormType.PROFILE
    )
  },

  isRequestFormTypeBasic(state) {
    return (
      state.contractSettings?.requestFormType?.id ===
      Constants.requestFormType.BASIC
    )
  },

  contractAcademicYears(state, getters) {
    let academicYears = []

    if (getters.hasAcademicYearPresetSettings) {
      academicYears = state.contractSettings.academicYearPresetSettings.map(
        (setting) => setting.academicYearPreset,
      )
    }

    const currentAcademicYearPresetIndex = state.academicYearPresets.findIndex(
      (preset) => preset.currentPreset,
    )

    return academicYears.map((academicYearObj) => {
      let { name } = academicYearObj

      const matchingPresetIndex = state.academicYearPresets.findIndex(
        (preset) => preset.id === academicYearObj.id,
      )

      if (currentAcademicYearPresetIndex === matchingPresetIndex) {
        name = `Текущий (${academicYearObj.name})`
      }

      if (currentAcademicYearPresetIndex === matchingPresetIndex - 1) {
        name = `Следующий (${academicYearObj.name})`
      }

      return {
        name,
        id: academicYearObj.id,
      }
    })
  },

  contractGrades(state, getters) {
    // Возможные классы в школах
    const result = {}

    for (const school of getters.contractSchools) {
      for (const grade of school.grades) {
        result[grade.id] = grade
      }
    }

    if (getters.isEducationTypeExtramural) {
      const resultFromSettings = {}

      state.allContractSettings.forEach((set) => {
        const { setting } = set

        setting?.academicYearPresetSettings?.forEach(
          (academicYearPresetSetting) => {
            academicYearPresetSetting.gradeSettings.forEach((gradeSetting) => {
              const gradeSet = gradeSetting.grade ?? gradeSetting

              resultFromSettings[gradeSet.id] = gradeSet
            })
          },
        )
      })

      return Object.values(resultFromSettings)
    }

    return Object.values(result)
  },

  educationType(state) {
    return state.contract?.educationType ?? {}
  },

  contractGrade(state, getters) {
    if (getters.hasSettings && state.contract.grade !== null) {
      if (
        !getters.contractGrades.find(
          (grade) => grade?.id === state.contract.grade.id,
        )
      ) {
        return null
      }
    }

    return state.contract.grade
  },

  contractSchool(state) {
    return state.contract.school
  },

  contractAcademicYearPreset(state) {
    const { academicYearPreset } = state.contract

    if (academicYearPreset !== null || academicYearPreset !== undefined) {
      return state.contract.academicYearPreset
    }

    return null
  },

  contractAcademicYearPresets(state) {
    const currentAcademicYearPresetIndex = state.academicYearPresets.findIndex(
      (academicYear) => academicYear.currentPreset,
    )

    if (
      currentAcademicYearPresetIndex !== -1 &&
      state.academicYearPresets.length > 0
    ) {
      return [
        {
          id: state.academicYearPresets[currentAcademicYearPresetIndex].id,
          name: `${i18n.global.t('components.contract.current_year')} (${
            state.academicYearPresets[currentAcademicYearPresetIndex].name
          })`,
        },
        // "Следующий" учебный год временно скрыт
        // {
        //   id: state.academicYearPresets[currentAcademicYearPresetIndex + 1].id,
        //   name: `${i18n.global.t('components.contract.next_year')} (${
        //     state.academicYearPresets[currentAcademicYearPresetIndex + 1].name
        //   })`,
        // },
      ]
    }

    return []
  },

  // BtnGroup

  contractSubmissionType(state) {
    return state.contract.submissionType
  },

  // ClientData

  clientKinshipDegree(state) {
    return state.contract.kinship?.kinshipDegree
  },

  clientKinshipConfirmDocName(state) {
    return state.contract.kinship?.confirmDocName
  },

  clientKinshipConfirmDocFile(state) {
    return state.contract.kinship?.confirmDocFile
  },

  clientAddressPermanent(state) {
    return state.contract.client.address?.permanent
  },

  clientAddressResidence(state) {
    return state.contract.client.address?.residence
  },

  clientAddressTemporary(state) {
    return state.contract.client.address?.temporary
  },

  clientAddressPermanentIndex(state) {
    return state.contract.client.address?.permanentIndex
  },

  clientAddressResidenceIndex(state) {
    return state.contract.client.address?.residenceIndex
  },

  clientAddressTemporaryIndex(state) {
    return state.contract.client.address?.temporaryIndex
  },

  clientAddressResidenceMatchesTemporary(state) {
    return state.contract.client.address?.residenceMatchesTemporary ?? false
  },

  clientAddressResidenceMatchesPermanent(state) {
    return state.contract.client.address?.residenceMatchesPermanent ?? false
  },

  clientPassportNumber(state) {
    return state.contract.client.passport?.number
  },

  clientPassportSeries(state) {
    return state.contract.client.passport?.series
  },

  clientPassportCountry(state) {
    return state.contract.client.passport?.country
  },

  clientPassportIssuedBy(state) {
    return state.contract.client.passport?.issuedBy
  },

  clientPassportIssueDate(state) {
    return state.contract.client.passport?.issueDate
  },

  clientPassportValidUntil(state) {
    return state.contract.client.passport?.validUntil
  },

  clientPassportMainPageFile(state) {
    return state.contract.client.passport?.mainPageFile
  },

  clientPassportRegistrationPageFile(state) {
    return state.contract.client.passport?.registrationPageFile
  },

  clientPassportTranslationFile(state) {
    return state.contract.client.passport?.translationFile
  },

  clientPassportConfirmingDocFile(state) {
    return state.contract.client.passport?.confirmingDocFile
  },

  clientPassportDepartmentCode(state) {
    return state.contract.client.passport?.departmentCode
  },

  clientCitizenship(state) {
    return state.contract.client.citizenship
  },

  clientMoscowRegistrationType(state) {
    return state.contract.client.moscowRegistration?.type
  },

  clientMoscowRegistrationEndDate(state) {
    return state.contract.client.moscowRegistration?.endDate
  },

  clientMoscowRegistrationStartDate(state) {
    return state.contract.client.moscowRegistration?.startDate
  },

  clientMoscowRegistrationConfirmDocName(state) {
    return state.contract.client.moscowRegistration?.confirmDocName
  },

  clientMoscowRegistrationConfirmDocType(state) {
    return state.contract.client.moscowRegistration?.confirmDocType
  },

  clientMoscowRegistrationConfirmDocFile(state) {
    return state.contract.client.moscowRegistration?.confirmDocFile
  },

  clientMoscowRegistrationConfirmDocNumber(state) {
    return state.contract.client.moscowRegistration?.confirmDocNumber
  },

  clientMoscowRegistrationConfirmDocIssuedBy(state) {
    return state.contract.client.moscowRegistration?.confirmDocIssuedBy
  },

  clientMoscowRegistrationConfirmDocIssuedDate(state) {
    return state.contract.client.moscowRegistration?.confirmDocIssueDate
  },

  clientAgreedWithPolitics(state) {
    return state.contract.agreedWithPolitics ?? false
  },

  clientAgreedWithProcessingPDExtramural(state) {
    return state.contract.agreedWithProcessingPDExtramural ?? false
  },

  clientAgreedWithPrivacyPolicyAndAgreement(state) {
    return state.contract.agreedWithPrivacyPolicyAndAgreement ?? false
  },

  clientAgreedWithProcessingPDFamily(state) {
    return state.contract.agreedWithProcessingPDFamily ?? false
  },

  clientAgreedWithRefundPolicy(state) {
    return state.contract.agreedWithRefundPolicy ?? false
  },

  clientSnilsFile(state) {
    return state.contract.client.snils?.file
  },

  clientSnilsNumber(state) {
    return state.contract.client.snils?.number
  },

  clientSnilsRegistrationDate(state) {
    return state.contract.client.snils?.registrationDate
  },

  //  StudentData

  studentFillDataTypes(state, getters) {
    const students = getters.studentsWithFullName

    if (students.length) {
      return state.studentFillDataTypes
    }

    return state.studentFillDataTypes.slice(0, 1)
  },

  studentsFilteredForRequest(state, getters, rootState) {
    const isEnrolled = (student) =>
      student.enrollmentStatus?.id === Constants.enrollmentStatus.ENROLLED
    const isExpelled = (student) =>
      student.enrollmentStatus?.id === Constants.enrollmentStatus.EXPELLED

    return rootState.client.students.students.filter(
      (student) =>
        student.hasOnlineSchool ||
        (!student.hasAwaitingPayment &&
          (student.isDemo ||
            isExpelled(student) ||
            (!isEnrolled(student) &&
              !student.hasActualEnrollmentContract &&
              !student.hasFutureEnrollmentContract))),
    )
  },

  studentsWithFullName(state, getters) {
    return cloneDeep(getters.studentsFilteredForRequest)
      .map((student) => {
        let fullName = ''

        if (student.lastName) {
          fullName += student.lastName
        }

        if (student.firstName) {
          fullName += ` ${student.firstName}`
        }

        if (student.middleName) {
          fullName += ` ${student.middleName}`
        }

        return {
          ...student,
          fullName,
        }
      })
      .filter((student) => student.fullName)
  },

  studentFillDataType(state) {
    return state.contract.studentFillDataType
  },

  isFillDataTypeStudentExisting(state, getters) {
    return (
      getters.studentFillDataType?.id ===
      Constants.studentFillDataTypes.STUDENT_EXISTING
    )
  },

  existingStudent(state) {
    return state.contract.existingStudent
  },

  studentSex(state) {
    return state.contract.student.sex
  },

  studentEmail(state) {
    return state.contract.student.email
  },

  studentPhone(state) {
    return state.contract.student.phone
  },

  studentStream(state) {
    return state.contract.stream
  },

  studentSnilsFile(state) {
    return state.contract.student.snils?.file
  },

  studentAdditionalFiles(state) {
    return state.contract.student.additionalFiles || []
  },

  studentCertificateFile(state) {
    return state.contract.student.certificateFile
  },

  studentSnilsNumber(state) {
    return state.contract.student.snils?.number
  },

  studentSnilsRegistrationDate(state) {
    return state.contract.student.snils?.registrationDate
  },

  studentHasDisability(state) {
    return state.contract.student.health?.hasDisability ?? false
  },

  studentHasRestriction(state) {
    return state.contract.student.health?.hasRestriction ?? false
  },

  studentDisabilityDocFile(state) {
    return state.contract.student.health?.disabilityDocFile
  },

  studentHasChronicDisease(state) {
    return state.contract.student.health?.hasChronicDisease ?? false
  },

  studentChronicDiseaseInformation(state) {
    return state.contract.student.health?.chronicDiseaseInformation
  },

  studentChronicDiseaseDocFile(state) {
    return state.contract.student.health?.chronicDiseaseDocFile
  },

  studentAddressPermanent(state) {
    return state.contract.student.address?.permanent
  },

  studentAddressResidence(state) {
    return state.contract.student.address?.residence
  },

  studentAddressTemporary(state) {
    return state.contract.student.address?.temporary
  },

  studentAddressPermanentIndex(state) {
    return state.contract.student.address?.permanentIndex
  },

  studentAddressResidenceIndex(state) {
    return state.contract.student.address?.residenceIndex
  },

  studentAddressTemporaryIndex(state) {
    return state.contract.student.address?.temporaryIndex
  },

  studentAddressResidenceMatchesTemporary(state) {
    return state.contract.student.address?.residenceMatchesTemporary ?? false
  },

  studentAddressResidenceMatchesPermanent(state) {
    return state.contract.student.address?.residenceMatchesPermanent ?? false
  },

  studentHasPassport(state) {
    return Boolean(state.contract.student.passport)
  },

  studentPassportNumber(state) {
    return state.contract.student.passport?.number
  },

  studentPassportSeries(state) {
    return state.contract.student.passport?.series
  },

  studentPassportCountry(state) {
    return state.contract.student.passport?.country
  },

  studentPassportIssuedBy(state) {
    return state.contract.student.passport?.issuedBy
  },

  studentPassportIssuedDate(state) {
    return state.contract.student.passport?.issueDate
  },

  studentPassportValidUntil(state) {
    return state.contract.student.passport?.validUntil
  },

  studentPassportMainPageFile(state) {
    return state.contract.student.passport?.mainPageFile
  },

  studentPassportRegistrationPageFile(state) {
    return state.contract.student.passport?.registrationPageFile
  },

  studentPassportDepartmentCode(state) {
    return state.contract.student.passport?.departmentCode
  },

  studentPassportTranslationFile(state) {
    return state.contract.student.passport?.translationFile
  },

  studentPassportConfirmingDocFile(state) {
    return state.contract.student.passport?.confirmingDocFile
  },

  studentBirthDate(state) {
    return state.contract.student.birthDate
  },

  studentFullYears(state) {
    return differenceInCalendarYears(
      new Date(),
      parseISO(dateFormatHelper.toIso(state.contract.student.birthDate)),
    )
  },

  studentFirstName(state) {
    return state.contract.student.firstName
  },

  studentLastName(state) {
    return state.contract.student.lastName
  },

  studentMiddleName(state) {
    return state.contract.student.middleName
  },

  studentCitizenship(state) {
    return state.contract.student.citizenship
  },

  studentPlaceOfBirth(state) {
    return state.contract.student.placeOfBirth
  },

  studentMedicalPolicyFile(state) {
    return state.contract.student.medicalPolicy?.file
  },

  studentMedicalPolicyNumber(state) {
    return state.contract.student.medicalPolicy?.number
  },

  studentIsPreferentialFamilyCategory(state) {
    return state.contract.student.isPreferentialFamilyCategory
  },

  studentFamilyCategory(state) {
    return state.contract.student.familyCategory
  },

  studentBirthCertificateFile(state) {
    return state.contract.student.birthCertificate?.file
  },

  studentBirthCertificateTranslationFile(state) {
    return state.contract.student.birthCertificate?.translationFile
  },

  studentBirthCertificateNumber(state) {
    return state.contract.student.birthCertificate?.number
  },

  studentBirthCertificateSeries(state) {
    return state.contract.student.birthCertificate?.series
  },

  studentBirthCertificateIssuedDate(state) {
    return state.contract.student.birthCertificate?.issueDate
  },

  studentMoscowRegistrationType(state) {
    return state.contract.student.moscowRegistration?.type
  },

  studentMoscowPermanentRegistrationTypes(state) {
    const { REG_CERTIFICATE_FORM_3 } =
      Constants.moscowRegistrationConfirmDocType

    return state.moscowRegistrationConfirmDocTypes.filter(
      ({ id }) => id !== REG_CERTIFICATE_FORM_3,
    )
  },

  studentMoscowTempRegistrationTypes(state) {
    const { REG_CERTIFICATE_FORM_3, OTHER } =
      Constants.moscowRegistrationConfirmDocType

    return state.moscowRegistrationConfirmDocTypes.filter(
      ({ id }) => id === REG_CERTIFICATE_FORM_3 || id === OTHER,
    )
  },

  studentMoscowRegistrationEndDate(state) {
    return state.contract.student.moscowRegistration?.endDate
  },

  studentMoscowRegistrationStartDate(state) {
    return state.contract.student.moscowRegistration?.startDate
  },

  studentMoscowRegistrationConfirmDocName(state) {
    return state.contract.student.moscowRegistration?.confirmDocName
  },

  studentMoscowRegistrationConfirmDocType(state) {
    return state.contract.student.moscowRegistration?.confirmDocType?.id
  },

  studentMoscowRegistrationConfirmDocFile(state) {
    return state.contract.student.moscowRegistration?.confirmDocFile
  },

  studentMoscowRegistrationRfRegistrationDocFile(state) {
    return state.contract.student.moscowRegistration?.rfRegistrationDocFile
  },

  studentMoscowRegistrationConfirmDocNumber(state) {
    return state.contract.student.moscowRegistration?.confirmDocNumber
  },

  studentMoscowRegistrationConfirmDocIssuedBy(state) {
    return state.contract.student.moscowRegistration?.confirmDocIssuedBy
  },

  studentMoscowRegistrationConfirmDocIssuedDate(state) {
    return state.contract.student.moscowRegistration?.confirmDocIssueDate
  },

  checkTemplateExists: (state) => (docTypeId) =>
    state.existingTemplates.some(
      (temp) => temp.documentTypeId === docTypeId && temp.isTemplateExists,
    ),

  isExistsTemplateMainDocument(state, getters) {
    return getters.checkTemplateExists(Constants.contractDocumentType.CONTRACT)
  },

  isExistsTemplateEnrollmentApplication(state, getters) {
    return getters.checkTemplateExists(
      Constants.contractDocumentType.ENROLLMENT_APPLICATION,
    )
  },

  isExistsTemplateReceipt(state, getters) {
    return getters.checkTemplateExists(Constants.contractDocumentType.RECEIPT)
  },

  isExistsTemplateAnnex(state, getters) {
    return getters.checkTemplateExists(Constants.contractDocumentType.ANNEX)
  },

  isExistsTemplateConditionalTransferAgreement(state, getters) {
    return getters.checkTemplateExists(
      Constants.contractDocumentType.CONDITIONAL_TRANSFER_AGREEMENT,
    )
  },

  isExistsTemplateAdmissionApplication(state, getters) {
    return getters.checkTemplateExists(
      Constants.contractDocumentType.ADMISSION_APPLICATION,
    )
  },

  isExistsTemplateIBLSUseApplication(state, getters) {
    return getters.checkTemplateExists(
      Constants.contractDocumentType.IBLS_USE_APPLICATION,
    )
  },

  isExistsTemplateCertTransferApplication(state, getters) {
    return getters.checkTemplateExists(
      Constants.contractDocumentType.CERT_TRANSFER_APPLICATION,
    )
  },

  isExistsTemplateConfirmationWithStamp(state, getters) {
    return getters.checkTemplateExists(
      Constants.contractDocumentType.CONFIRMATION_WITH_STAMP,
    )
  },

  hasTemplates(state, getters) {
    return (
      getters.isExistsTemplateMainDocument ||
      getters.isExistsTemplateEnrollmentApplication ||
      getters.isExistsTemplateReceipt ||
      getters.isExistsTemplateAnnex ||
      getters.isExistsTemplateConditionalTransferAgreement ||
      getters.isExistsTemplateAdmissionApplication ||
      getters.isExistsTemplateIBLSUseApplication ||
      getters.isExistsTemplateCertTransferApplication ||
      getters.isExistsTemplateConfirmationWithStamp
    )
  },

  isShowIncomeDiagnosticButton(state, getters, rootState) {
    return rootState.client.profile.profile?.showIncomeDiagnosticButton
  },

  currentStepName(state) {
    const currentStep =
      state.contract?.steps?.find((step) => step.isActive) ?? null

    return currentStep?.heading ?? null
  },

  statusData(state, getters, rootState) {
    return {
      clientId: rootState.client.profile.profile?.id,
      step: getters.currentStepName,

      clientFirstname: state.contract.client.firstName,
      clientLastName: state.contract.client.lastName,
      clientPhone: state.contract.client.newPhone,
      clientEmail: state.contract.client.email,

      studentFirstname: state.contract.student.firstName,
      studentLastname: state.contract.student.lastName,
      studentEmail: state.contract.student.email,

      requestGrade: state.contract.grade?.id ?? null,
      requestNumber: state.requestNumber ?? null,
      requestEducationTypeId: state.contract.educationType?.id ?? null,
      requestSchoolId: state.contract.school?.id ?? null,
      requestAcademicYear: state.contract.academicYearPreset?.name ?? null,
    }
  },

  schoolPolicyFileUrl(state) {
    return state.school?.policyFile?.fileUrl ?? ''
  },

  schoolPDFileUrl(state) {
    return state.school?.personalDataFile?.fileUrl ?? ''
  },
}
