export default {
  hasInvoices(state) {
    return state.invoices.length > 0
  },

  offerDocs(state) {
    return state.offerDocs.filter((offerDoc) => offerDoc.viewAgreementCheckbox)
  },

  hasProducts(state) {
    return state.invoice.products.length > 0
  },

  hasProductsInactive(state) {
    return state.invoice.products.some(
      (item) => !item.isActive || !item.hasVacantSeats,
    )
  },
}
