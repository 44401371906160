import { ApiMarkUpStorage } from 'ApiRest/Api/MarkUp.js'

export default {
  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} id
   * @returns {Promise<void>}
   */
  async fetchMarkUp({ commit }, id) {
    const { data } = await ApiMarkUpStorage.get(id)

    commit('setMarkUp', data.markup)
  },

  resetState({ commit }) {
    commit('resetState')
  },
}
