import { StoreUserShowcase } from '@/StoreUserShowcase'

const checkAuth = (to, from, next) => {
  const isAuthorized =
    StoreUserShowcase.store.getters['common/user/isAuthorized']

  if (isAuthorized) {
    next('/')
  } else {
    next()
  }
}

/**
 * Некоторые страницы авторизации были перенесены в отдельную репу (ibls-widgets).
 * Следующие роуты больше не обрабатываются приложением:
 * - login
 * - logout
 * - password-reset
 * - password-reset-confirm
 *
 * upd: - password-reset возвращён и переименован в recover в рамках задачи
 * https://redmine.ibls.tech/issues/9838
 */

export const authRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/auth/Login.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
  },
  {
    path: '/create-password',
    // TODO: В e-mail указывается ссылка с этим путем.
    //  При изменении согласовать с бэком.
    name: 'password-create',
    component: () => import('@/pages/auth/PasswordCreate.vue'),
  },
  {
    path: '/recover',
    name: 'password-reset',
    component: () => import('@/pages/auth/PasswordReset.vue'),
  },
  {
    path: '/change-password',
    // TODO: В e-mail указывается ссылка с этим путем.
    //  При изменении согласовать с бэком.
    name: 'password-reset-confirm',
    component: () => import('@/pages/auth/PasswordResetConfirm.vue'),
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('@/pages/auth/Registration.vue'),
  },
  /*   {
    path: '/demo-registration',
    name: 'demo-registration',
    component: () =>
      import(
        '@/pages/auth/DemoRegistration.vue'
      ),
  },
  {
    path: '/educont-registration',
    name: 'educont-registration',
    component: () =>
      import(
        '@/pages/auth/EducontRegistration.vue'
      ),
  }, */
  {
    path: '/confirm-email',
    name: 'confirm-email',
    component: () => import('@/pages/auth/ConfirmEmail.vue'),
  },
]

authRoutes.forEach((route) => {
  if (route.name !== 'password-create') {
    route.beforeEnter = checkAuth
  }
})
