export const studentMainRoutes = [
  {
    path: '',
    name: 'student-main',
    component: () => import('@/pages/student/PageMain.vue'),
  },
  {
    path: 'calendar',
    name: 'calendar',
    component: () => import('@/pages/student/Calendar.vue'),
  },
  {
    path: 'calendar/:day([\\d\\-]{10})',
    name: 'calendar-day',
    component: () => import('@/pages/student/Calendar.vue'),
  },
  // TODO: temporary hide link. https://redmine.ibls.tech/issues/4139. Согласовано с Ушаковым
  // {
  //  path: 'events',
  //  name: 'student-events',
  //  component: () =>
  //    import(
  //      '@/pages/student/Events.vue'
  //    ),
  // },
  // {
  //  path: 'events/:eventId(\\d+)',
  //  name: 'student-event',
  //  component: () =>
  //    import(
  //      '@/pages/student/TheEvent.vue'
  //    ),
  // },
  {
    path: 'goals',
    name: 'goals',
    component: () => import('@/pages/student/Goals.vue'),
  },
  {
    path: 'lessons',
    name: 'lessons',
    component: () => import('@/pages/student/Lessons.vue'),
  },
  {
    path: 'lessons/:lessonId(\\d+)',
    name: 'lesson',
    component: () => import('@/pages/student/TheLesson.vue'),
  },
  {
    path: 'news',
    name: 'student-news',
    component: () => import('@/pages/student/News.vue'),
  },
  {
    path: 'news/:newsArticleId(\\d+)',
    name: 'student-news-article',
    component: () => import('@/pages/student/TheNewsArticle.vue'),
  },
  {
    path: 'statements',
    name: 'student-statements',
    component: () => import('@/pages/student/Statements.vue'),
  },
  {
    path: 'statements/:groupId(\\d+)',
    name: 'student-statement',
    component: () => import('@/pages/student/TheStatement.vue'),
  },
  {
    path: 'certification',
    name: 'certification',
    component: () => import('@/pages/student/Certification.vue'),
  },
  {
    path: 'notifications',
    name: 'notifications',
    component: () => import('@/pages/common/Notifications.vue'),
  },
  {
    path: 'messages',
    name: 'student-messages',
    component: () => import('@/pages/common/Messages.vue'),
  },
  {
    path: 'profile',
    name: 'student-profile',
    component: () => import('@/pages/student/Profile.vue'),
  },
  {
    path: 'schedule-tests',
    name: 'schedule-tests',
    component: () => import('@/pages/student/ScheduleTests.vue'),
  },
  {
    path: 'schedule-lessons',
    name: 'schedule-lessons',
    component: () => import('@/pages/student/ScheduleLessons.vue'),
  },
  {
    path: 'library',
    name: 'student-library',
    component: () => import('@/pages/student/Library.vue'),
  },

  {
    path: 'online-lessons',
    name: 'online-lessons',
    component: () => import('@/pages/student/LessonsOnline.vue'),
  },
  {
    path: 'online-lessons/:lessonId(\\d+)',
    name: 'the-online-lesson',
    component: () => import('@/pages/student/LessonsOnline.vue'),
  },
  {
    path: 'income-diagnostics',
    name: 'student-income-diagnostics',
    component: () => import('@/pages/student/IncomeDiagnostics.vue'),
  },
  {
    path: 'payment/success',
    redirect: {
      name: 'student-main',
    },
  },
  {
    path: 'payment/error',
    redirect: {
      name: 'student-payment',
      query: {
        status: 'error',
      },
    },
  },
  {
    path: 'teenology',
    name: 'student-teenology',
    component: () => import('@/pages/student/Teenology.vue'),
  },
]
