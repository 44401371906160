// Директива для перехвата ссылок, например, в v-html

const linkInterceptor = (router, store) => ({
  mounted(el) {
    const getRouteNameOnUser = (url) =>
      store.getters[`common/user/getRouteNameOnUser`](url)

    el.addEventListener('click', async (event) => {
      const target = event.target.closest('a')

      if (target) {
        event.preventDefault()
        const href = target.getAttribute('href')

        if (!href.startsWith('http')) {
          await router.push({
            name: getRouteNameOnUser(href),
          })
        }
      }
    })
  },
})

export default linkInterceptor
