import ApiBase from 'ApiRest/ApiBase'

export const ApiMarkUpStorage = new (class MarkUpStorage extends ApiBase {
  /**
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/markup-storage/${id}`)
  }

  /**
   * @param {Object} data
   * @param {number} id
   * @returns {Promise}
   */
  post(data, id) {
    return this._POST(`/markup-storage/${id}`, data)
  }
})()
