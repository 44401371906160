<template>
  <router-view v-if="!isLoading" />
  <LoaderFullScreen v-else />

  <ModalsContainer />
</template>

<script>
import LoaderFullScreen from '@/components/common/LoaderFullScreen.vue'
import { isShowcaseDomain } from '@/helpers/domain'
import { initTrackers } from '@/helpers/trackers/init'
import { ModalsContainer } from 'vue-final-modal'

export default defineComponent({
  name: 'App',

  components: {
    LoaderFullScreen,
    ModalsContainer,
  },

  data() {
    return {
      isLoadingFonts: true,
    }
  },

  head() {
    const resultHead = {
      script: [],

      titleTemplate: (titleChunk) => {
        if (isShowcaseDomain) {
          return titleChunk || 'Академия IBLS'
        }

        return titleChunk ? `${titleChunk} | IBLS` : `IBLS`
      },
    }

    return resultHead
  },

  computed: {
    ...mapState('common/user', [
      'id',
    ]),

    ...mapState([
      'isGlobalLoading',
    ]),

    isLoading() {
      return this.isGlobalLoading || this.isLoadingFonts
    },
  },

  watch: {
    isGlobalLoading: {
      async handler(newValue) {
        if (!newValue) {
          await initTrackers(this.id)
        }
      },

      immediate: true,
    },
  },

  created() {
    this.init()
  },

  methods: {
    async init() {
      await this.initFonts()
    },

    async initFonts() {
      try {
        await document.fonts.ready
      } finally {
        this.isLoadingFonts = false
      }
    },
  },
})
</script>

<style lang="scss">
@import '~@/app';
</style>
